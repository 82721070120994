import React, {Component} from 'react'
import SideMenu from '../admin/SideMenu';
import {Modal} from 'reactstrap';
import TopMenu from '../admin/TopBar';
import {appDatabasePrimaryFunctions, base} from '../../base';
import '../../styles/css/AdminMain.css';
import swal from 'sweetalert2';
import AuthService from '../utils/AuthService';
import DatePicker from '../utils/DatePicker';
import {
    cleanCSVText,
    convertTimeStampToHumanReadable,
    getSnapshotFromEndpoint,
    findFirstAndLastName,
    valueDoesExist,
    returnTimeStampInMilliseconds
} from '../utils/HelpfulFunction';
import {Accordion, AccordionItem} from 'react-sanfona';
import TimePicker from 'react-time-picker';

class SetUpGame extends Component {
    constructor(props) {
        super(props);
        this.Auth = new AuthService();
        this.state = {
            ticketList: [],
            questionsList: [],
            tenantVariables: {},
            emailsSent: {},
            emailVariables: {},
            tenantRules: {},
            id_key: "",
            userAnswers:[],
            currentGame: null,
            modal: false,
            gameName: "",
            loading: true,
            wizardLocation: 'first',
            showStats: false,
            showPercentages: "never",
            prizeType: "allCorrect",
            predictionsAnsweredModal: false,
            endTime: new Date(),
            startTime: new Date(),
            itemsPerPage: 25,
            itemsToShow: 25
        };
        this.handleChange = this.handleChange.bind(this);
        this.toggle = this.toggle.bind(this);
        this.togglePredictionsAnsweredModal = this.togglePredictionsAnsweredModal.bind(this);
        this.handleTimeChange = this.handleTimeChange.bind(this);
    }

    componentDidMount() {
        const vm = this;
        this.tenantVariablesRef = base.bindToState(`tenantVariables`, {
            context: this,
            state: 'tenantVariables',
        });
        this.totalAnswersCountRef = base.syncState('tenantStats', {
            context: this,
            state: 'tenantStats',
        })
        this.idKeyRef = base.fetch(`id_key`, {
            context: this,
            then(data){
              this.setState({
                id_key: data
              })
            }
        });
        this.emailVariablesRef = base.bindToState(`emailVariables`, {
            context: this,
            state: 'emailVariables',
        });
        this.tenantRulesRef = base.bindToState(`tenantRules`, {
            context: this,
            state: 'tenantRules',
        });
        this.rewardsListRef = base.syncState(`rewardsList`, {
            context: this,
            state: 'ticketList',
            asArray: true
        });
        this.questionsListRef = base.bindToState('questionsList',{
            context: this,
            state: 'questionsList',
            asArray: true
        })
        this.userAnswersRef = appDatabasePrimaryFunctions.ref("userAnswers").on('value', function(snapshot){
            vm.setState({
                userAnswerCount: snapshot.numChildren()
            })
        })
        this.currentGameRef = base.syncState(`currentGame`, {
            context: this,
            state: 'currentGame',
            then(err){
              if(!err){
                this.setGameToWatch(this.state.currentGame.id);
                this.listenToChangesInQuestion()
              }
              this.setState({loading:false})
            }
        });
    }

    downloadUsers(){
      this.setState({loading: true});
      const vm = this;
      let csv = 'Email,First Name,Last Name,Zip Code,Birthday,Opt-In,Answer,Reward,Code,Answer Time\n';
      Promise.all([getSnapshotFromEndpoint('users', appDatabasePrimaryFunctions)]).then(async function(snapshots){
          let users = {};
          if(snapshots[0].exists() && snapshots[0].val()){
              users = snapshots[0].val();
          }
          let answerList = await vm.organizeUserAnswers();
          answerList.forEach(function(row) {
              let {firstName, secondName} = findFirstAndLastName(users[row.uid].name);
              csv += (users[row.uid].email || "");
              csv+= ",";
              csv+= (cleanCSVText(firstName) || "");
              csv+= ",";
              csv+= (cleanCSVText(secondName) || "");
              csv+= ",";
              csv+= (cleanCSVText(users[row.uid].zipCode) || "");
              csv+= ",";
              csv+= (users[row.uid].birthday || "");
              csv+= ",";
              csv+= (users[row.uid].optIn || "");
              csv+= ",";
              csv+= cleanCSVText(valueDoesExist(row.answer)?row.answer:"");
              csv+= ",";
              csv+= (cleanCSVText(row.rewardSent) || "NONE");
              csv+= ",";
              csv+= (cleanCSVText(row.code) || "-");
              csv+= ",";
              csv += convertTimeStampToHumanReadable(parseInt(row.timeStamp));
              csv += "\n";
          }, this);
          let hiddenElement = document.createElement('a');
          hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
          hiddenElement.target = '_blank';
          let date_got = new Date((new Date().getTime() - new Date().getTimezoneOffset()*60*1000)).toISOString().split("T")[0] + ".csv"
          hiddenElement.download = "users_" + date_got;
          hiddenElement.click();
          vm.setState({loading: false})
      })
    }

    listenToChangesInQuestion(){
        if(this.currentGameQuestionsRef){
            base.removeBinding(this.currentGameQuestionsRef);
        }
      this.currentGameQuestionsRef = base.listenTo(`currentGame/questions`, {
        context: this,
        then(currentGameQuestionList){
          var allQuestionsShown = true
          for(var questionIndex in currentGameQuestionList){
            var question = currentGameQuestionList[questionIndex];
            if(!question.shown){
              allQuestionsShown = false
            }
          }
          if(allQuestionsShown && currentGameQuestionList.length > 0){
            // this.distrib uteMessaging();
          }
        }
      });
    }

    setGameToWatch(id){
      if(this.userGameHistoryRef){
          appDatabasePrimaryFunctions.ref('userGameHistory').off('value', this.userGameHistoryRef);
      }
      if(id){
          const vm = this;
          this.userGameHistoryRef = appDatabasePrimaryFunctions.ref("userGameHistory").orderByChild(id).equalTo(id).on('value', function(snapshot){
              vm.setState({
                  userGameHistory: snapshot.numChildren()
              })
          })
      }
    }

    componentWillUnmount() {
        if(this.userGameHistoryRef){
            appDatabasePrimaryFunctions.ref('userGameHistory').off('value', this.userGameHistoryRef);
        }
        appDatabasePrimaryFunctions.ref('userAnswers').off('value', this.userAnswersRef);
        base.removeBinding(this.tenantVariablesRef);
        if(this.currentGameQuestionsRef){
            base.removeBinding(this.currentGameQuestionsRef);
        }
        base.removeBinding(this.rewardsListRef);
        base.removeBinding(this.questionsListRef);
        base.removeBinding(this.currentGameRef);
        base.removeBinding(this.totalAnswersCountRef);
        base.removeBinding(this.tenantRulesRef);
        base.removeBinding(this.emailVariablesRef);
        base.removeBinding(this.idKeyRef);
    }

    findItem(itemId, list){
      for(let itemIndex in list){
        let item = list[itemIndex]
        if(item.key === itemId){
          return Object.assign({}, item);
        }
      }
    }

    detectHowManyCodesEntered(codesArray){
        if(!codesArray){
            return []
        }
        const splitOnLineBreaks = codesArray.split("\n");
        let splitOnWhiteSpace = codesArray.split(/\s+/);
        let splitOnCommas = codesArray.split(",");
        let splitArray = splitOnLineBreaks;
        if(splitOnWhiteSpace.length === splitOnCommas.length){
            splitOnWhiteSpace = codesArray.replace(/,/g, '').split(/\s+/);
            splitOnCommas = codesArray.replace(/\s/g,'').split(",");
        }
        if(splitArray.length < splitOnWhiteSpace.length){
            splitArray = splitOnWhiteSpace
        }
        if(splitArray.length < splitOnCommas.length){
            splitArray = splitOnCommas
        }
        return splitArray
    }

    async createGame(){
        let createGameObject = {};
        let gameId = appDatabasePrimaryFunctions.ref().push().key;
        let questionAdded = this.state.question_select;
        let startTime = this.state.startTime;
        let endTime = this.state.endTime;
        let main_prize = this.state.main_prize;
        let main_prize_codes = this.state.main_prize_codes;
        let main_prize_amount = this.state.main_prize_amount;
        let other_prize = this.state.other_prize;
        let losing_prize = this.state.losing_prize;
        main_prize = this.findItem(main_prize, this.state.ticketList);
        other_prize = this.findItem(other_prize, this.state.ticketList);
        losing_prize = this.findItem(losing_prize, this.state.ticketList);
        if(!startTime || !endTime){
            swal({
                title: "Hold On!",
                text: 'The game needs a start time and end time!',
                type: 'warning',
                confirmButtonText: 'Ok'
            })
            return
        }
        startTime = startTime.getTime();
        endTime = endTime.getTime();
        if(startTime >= endTime){
            swal({
                title: "Hold On!",
                text: 'The game start time cannot be equal to or greater than the game end time!',
                type: 'warning',
                confirmButtonText: 'Ok'
            })
            return
        }
        if(startTime <= Date.now()){
            swal({
                title: "Hold On!",
                text: 'The game start time cannot be before now!',
                type: 'warning',
                confirmButtonText: 'Ok'
            })
            return
        }
        if(!main_prize && !other_prize && !losing_prize){
            swal({
                title: "Hold On!",
                text: "No reward added. You need to add at a Main Prize, Other Winner Prize, or a Losing Prize",
                type: 'warning',
                confirmButtonText: 'Ok'
            })
            return;
        }
        if(main_prize && main_prize.rewardName && (!main_prize_amount || 1 > main_prize_amount)){
            swal({
                title: "Hold On!",
                text: "You have a main prize selected but no amount added with it.  It needs some amount other wise make it an Other Winner Prize",
                type: 'warning',
                confirmButtonText: 'Ok'
            })
            return;
        }
        let main_prize_codesArray = this.state.main_prize_codesArray;
        if(main_prize_codes){
            main_prize_codesArray = this.detectHowManyCodesEntered(main_prize_codesArray)
            if(main_prize_codesArray.length === 0 && main_prize_codes){
                swal({
                    title: 'Hold on!',
                    text: 'Must enter codes or uncheck codes box!',
                    type: 'warning',
                    confirmButtonText: 'Ok'
                })
                return;
            } else if (main_prize_codes){
                if(main_prize_codesArray.length !== parseInt(main_prize_amount,10)){
                    swal({
                        title: 'Codes not equal!',
                        text: "# of codes must equal number of rewards created. In this case: " +  main_prize_codesArray.length + " does not equal " + main_prize_amount,
                        type: 'warning',
                        confirmButtonText: 'Ok'
                    })
                    return;
                }
                main_prize.codes = main_prize_codesArray;
            }
        } else if(main_prize) {
            main_prize.codes = null;
        }
        if(main_prize){
            main_prize.amount = main_prize_amount;
            main_prize.emailImage = main_prize.emailImage || "";
            createGameObject.main_prize = main_prize;
        } else {
            createGameObject.main_prize = false;
        }
        if(other_prize){
            other_prize.emailImage = other_prize.emailImage || "";
            createGameObject.other_prize = other_prize;
        } else {
            createGameObject.other_prize = false;
        }
        if(losing_prize){
            losing_prize.emailImage = losing_prize.emailImage || "";
            createGameObject.losing_prize = losing_prize;
        } else {
            createGameObject.losing_prize = false;
        }
        let gameName = this.state.gameName.trim();
        if(!gameName){
        swal({
            title: "Hold On!",
            text: 'The game needs a name!',
            type: 'warning',
            confirmButtonText: 'Ok'
          })
        return
        }
        if(!questionAdded){
            swal({
                title: "Hold On!",
                text: 'The game needs a prediction!',
                type: 'warning',
                confirmButtonText: 'Ok'
            })
            return
        }
        createGameObject.gameName = gameName;
        createGameObject.question = this.findItem(questionAdded, this.state.questionsList);
        if(createGameObject.question && createGameObject.question.questionType !== "score"){
            createGameObject.question.teamOneImage = null;
            createGameObject.question.teamTwoImage = null;
        }
        if(createGameObject.question && !createGameObject.question.answerImage){
            createGameObject.question.answerImage = false;
        }
        createGameObject.firstStart = false;
        createGameObject.correctAnswer = false;
        createGameObject.scheduleInfo = {};
        createGameObject.scheduleInfo['status'] = 'scheduled';
        createGameObject.scheduleInfo['performAt'] = startTime;
        createGameObject.scheduleInfo['endAt'] = endTime;
        const emailsSentCount = await appDatabasePrimaryFunctions.ref('emailsSent').once('value').then(function (snapshot) {
            return snapshot
        })
        this.setState({loading:true})
        let userAnswerCount = this.state.userAnswerCount;
        let currentGame = this.state.currentGame || {};
        if(currentGame.gameName){
            currentGame['users'] = this.state.userGameHistory;
            currentGame['answers'] = userAnswerCount;
            currentGame['prizesWon'] = emailsSentCount.numChildren();
            currentGame['active'] = null;
            currentGame['answered'] = null;
            currentGame['prizeType'] = null;
            currentGame['firstStart'] = null;
            currentGame['timeMinutes'] = null;
        }
        const vm = this;
        const organizeUserAnswers = await this.organizeUserAnswers();
        base.post(`userAnswersHistory/${currentGame.id}`, {
            data: organizeUserAnswers,
            then(data){
                console.log("User Answers Saved!")
            }
        })
        base.push('gamesList', {
            data: currentGame,
            then(err) {
                if (!err) {
                    createGameObject.id = gameId;
                    createGameObject.timeStamp = base.timestamp;
                    createGameObject.answered = false;
                    createGameObject.active = false;
                    let pastAnswerCount = vm.state.userAnswerCount || 0;
                    let allAnswers = vm.state.tenantStats.allAnswers || 0;
                    allAnswers = parseInt(allAnswers) + pastAnswerCount;
                    allAnswers = {allAnswers: allAnswers}
                    vm.setState({
                        currentGame: createGameObject,
                        tenantStats: allAnswers,
                        modal: false,
                        main_prize: null,
                        other_prize: null,
                        losing_prize: null,
                        main_prize_amount: 0,
                        main_prize_codesArray: [],
                        main_prize_codes: false,
                        loading: false,
                        currentUserAnswersState: []
                    }, () => {
                        vm.setGameToWatch(gameId);
                        appDatabasePrimaryFunctions.ref("userAnswers").set(null);
                        appDatabasePrimaryFunctions.ref("emailsSent").set(null);
                    })
                } else {
                    alert("Error Occurred: " + err)
                    vm.setState({
                        loading: false
                    })
                }
            }
        })
    }

    sendAllEmails(correctList, incorrectList, currentGame, correctListNames, inCorrectListNames){
        const emailVariables = this.state.emailVariables;
        const rulesAndRegsVars = this.state.tenantRules;
        const from_who = emailVariables.sender || "winner@rewards.sqwadapp.co";
        const subject = emailVariables.subjectLine;
        const email_help_text = emailVariables.helpText;
        const header_image = emailVariables.emailBackgroundImage;
        const rules_regs_link = rulesAndRegsVars.rulesAndRegsLink;
        if(!currentGame.main_prize && !currentGame.other_prize && !currentGame.losing_prize){
            swal({
                title: 'No Reward Added So No Emails Sent',
                type: 'success',
                toast: true,
                position: 'top-end',
                showConfirmButton: true,
                timer: 10000
            })
            this.setState({
                loading: false,
                currentGame: currentGame,
                predictionsAnsweredModal: false
            })
            return
        }
        if(correctList.length === 0 && incorrectList === 0){
            swal({
                title: 'Emails Sent!',
                type: 'success',
                toast: true,
                position: 'top-end',
                showConfirmButton: true,
                timer: 10000
            })
            this.setState({
                loading: false,
                currentGame: currentGame,
                predictionsAnsweredModal: false
            })
            return
        }
        if(correctList.length === 0 && !currentGame.losing_prize){
            swal({
                title: 'Emails Sent!',
                type: 'success',
                toast: true,
                position: 'top-end',
                showConfirmButton: true,
                timer: 10000
            })
            this.setState({
                loading: false,
                currentGame: currentGame,
                predictionsAnsweredModal: false
            })
            return
        }
        const gameObject = {"from_who": from_who, "subject": subject, "correctList": correctList,
            "header_image": header_image, "incorrectList": incorrectList, "main_prize": currentGame.main_prize,
            "other_prize": currentGame.other_prize, "loser_prize": currentGame.losing_prize,
            "email_help_text": email_help_text, "rules_regs_link": rules_regs_link,
            "correctListNames": correctListNames, "inCorrectListNames": inCorrectListNames, "tag": process.env.REACT_APP_FIREBASE_PROJECT_ID};
        this.setState({
            loading:true
        })
        this.Auth.fetch('https://still-fjord-64720.herokuapp.com/rewards/mainotherloserprizing', {
        // this.Auth.fetch('http://localhost:5000/rewards/mainotherloserprizing', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'IDToken': this.state.id_key
            },
            body: JSON.stringify(gameObject)
        }).then(async response => {
            if(!response){
                swal({
                    title: 'Something went wrong!  Check your internet connection and try again!',
                    type: 'error',
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: true,
                    timer: 10000
                })
                this.setState({
                    loading: false,
                })
                return
            }
            if(response.message === "Emails sent!"){
                swal({
                    title: 'Emails Sent!',
                    type: 'success',
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: true,
                    timer: 10000
                })
                let gameToSave = {}
                if(response.main_prize_receivers.length > 0 && currentGame.main_prize){
                    for(let main_prize_receivers_index in response.main_prize_receivers){
                        let rewardToSave = {}
                        rewardToSave.name = currentGame.main_prize.rewardName
                        rewardToSave.type = "main"
                        let codeSent = false
                        if(response.codes.length > 0){
                            codeSent = response.codes[main_prize_receivers_index]
                            rewardToSave.code = codeSent
                        }
                        let email = response.main_prize_receivers[main_prize_receivers_index]
                        let baseEncodedEmail = btoa(email)
                        gameToSave[baseEncodedEmail] = rewardToSave
                    }
                }
                if(response.other_prize_receivers.length > 0 && currentGame.other_prize){
                    for(let other_prize_receivers_index in response.other_prize_receivers){
                        let rewardToSave = {}
                        rewardToSave.name = currentGame.other_prize.rewardName;
                        rewardToSave.type = "other"
                        rewardToSave.code = false;
                        let email = response.other_prize_receivers[other_prize_receivers_index]
                        let baseEncodedEmail = btoa(email)
                        gameToSave[baseEncodedEmail] = rewardToSave
                    }
                }
                if(response.incorrectList.length > 0 && currentGame.losing_prize){
                    for(let incorrectList_prize_receivers_index in response.incorrectList){
                        let rewardToSave = {}
                        rewardToSave.name = currentGame.losing_prize.rewardName;
                        rewardToSave.type = "losing"
                        rewardToSave.code = false;
                        let email = response.incorrectList[incorrectList_prize_receivers_index]
                        let baseEncodedEmail = btoa(email)
                        gameToSave[baseEncodedEmail] = rewardToSave
                    }
                }
                base.update(`emailsSent`, {
                    data: gameToSave,
                    then(err){
                        if(!err){
                            console.log("success")
                        }
                    }
                })
            } else {
                console.log("ERROR")
                console.log(response)
            }
            const organizeUserAnswers = await this.organizeUserAnswers(this.state.itemsPerPage);
            this.setState({
                loading: false,
                currentGame: currentGame,
                predictionsAnsweredModal: false,
                currentUserAnswersState: organizeUserAnswers
            })
        })
    }

    stopGame(){
      let currentGame = this.state.currentGame;
      currentGame.active = false;
      this.setState({
        currentGame: currentGame
      })
      swal({
        title: 'Game Stopped',
        type: 'error',
        toast: true,
        position: 'top-end',
        showConfirmButton: true,
        timer: 10000
      })
    }

    startGame() {
        let currentGame = this.state.currentGame;
        this.setState({loading:true})
        currentGame.timeStamp = Date.now()
        currentGame.firstStart = true;
        currentGame.active = true;
        this.setState({
            currentGame: currentGame,
            loading: false
        })
        swal({
            title: 'Game Started',
            type: 'success',
            toast: true,
            position: 'top-end',
            showConfirmButton: true,
            timer: 10000
        })
    }

    endGame(){
        this.togglePredictionsAnsweredModal();
    }

    handleChange (evt) {
      let target = evt.target;
      let value = target.type === 'checkbox' ? target.checked : target.value;
      this.setState({ [evt.target.name]: value });
    }

    handleTimeChange(event){
        this.setState({
            correctAnswer: event
        })
    }

    toggle() {
      this.setState({
        modal: !this.state.modal,
        wizardLocation: "first",
      });
    }

    togglePredictionsAnsweredModal() {
        this.setState({
            predictionsAnsweredModal: !this.state.predictionsAnsweredModal,
        });
    }

    resetGame(){
      swal({
          title: 'STOP!',
          text: 'Are you sure you want to do this?  This will erase all information gathered during this game! Only do this if no one has played yet.',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'YES I WANT TO RESET THE GAME',
        }).then(async (result)=>{
          if(result.value){
            let currentGameState = await appDatabasePrimaryFunctions.ref('currentGame').once('value').then(function(snapshot){
                return snapshot.val() || {};
            });
            currentGameState.firstStart = false;
            currentGameState.answered = false;
            currentGameState.active = false;
            currentGameState.correctAnswer = false;
            let history = await appDatabasePrimaryFunctions.ref(`userGameHistory`).orderByChild(currentGameState.id).equalTo(currentGameState.id).once('value').then(function(snapshot){
                return snapshot.val() || {};
            });
            for(let questionIndex in currentGameState.question.answers){
               currentGameState.question.answers[questionIndex].correct = false
            }
            for(let historyRef in history){
              let refInstance = history[historyRef];
              if(refInstance && refInstance[currentGameState.id]){
                  appDatabasePrimaryFunctions.ref(`userGameHistory/${historyRef}/${currentGameState.id}`).set(null);
              }
            }
            if(currentGameState.id){
                appDatabasePrimaryFunctions.ref(`currentGameFormFilled/${currentGameState.id}`).set(null);
            }
            appDatabasePrimaryFunctions.ref("emailsSent").set(null);
            appDatabasePrimaryFunctions.ref("userAnswers").set(null);
            this.setState({
                currentGame: currentGameState,
                currentUserAnswersState: []
            })
          }
        })
    }

    navButtonClicked(direction){
      var currentLocation = this.state.wizardLocation;
      if(direction === 'prev' && currentLocation === 'second'){
        this.setState({
          wizardLocation: 'first'
        })
        this.toggleWizardLocation('first', 'second', 'third')
      } else if(direction === 'prev' && currentLocation === 'third'){
        this.setState({
          wizardLocation: 'second'
        })
        this.toggleWizardLocation('second', 'first', 'third')
      } else if(direction === 'next' && currentLocation === 'first'){
        this.setState({
          wizardLocation: 'second',
        })
        this.toggleWizardLocation('second', 'first', 'third')
      } else if(direction === 'next' && currentLocation === 'second'){
        this.setState({
          wizardLocation: 'third',
        })
        this.toggleWizardLocation('third', 'first', 'second')
      } else {
        this.setState({
          wizardLocation: 'first'
        })
        this.toggleWizardLocation('first', 'second', 'third')
      }
    }

    toggleWizardLocation(tabClicked, otherTab1, otherTab2){
      this.setState({
        wizardLocation: tabClicked
      })
      document.getElementById(tabClicked).classList.add('active');
      document.getElementById(tabClicked).classList.add('show');
      document.getElementById(otherTab1).classList.remove('active');
      document.getElementById(otherTab1).classList.remove('show');
      document.getElementById(otherTab2).classList.remove('active');
      document.getElementById(otherTab2).classList.remove('show');
      document.getElementById(tabClicked+'1').classList.add('active');
      document.getElementById(tabClicked+'1').classList.add('show');
      document.getElementById(otherTab1+'1').classList.remove('active');
      document.getElementById(otherTab1+'1').classList.remove('show');
      document.getElementById(otherTab2+'1').classList.remove('active');
      document.getElementById(otherTab2+'1').classList.remove('show');
    }

   async switchStatsPrizes(){
      if(this.state.showStats){
        document.getElementById('showPrizes').classList.remove('active');
        document.getElementById('showStats').classList.add('active');
        this.setState({
          showStats:false,
        })
      }else{
        document.getElementById('showPrizes').classList.add('active');
        document.getElementById('showStats').classList.remove('active');
        this.setState({
            loading: true
        })
        const organizeUserAnswers = await this.organizeUserAnswers(this.state.itemsPerPage);
        this.setState({
            showStats:true,
            loading: false,
            currentUserAnswersState: organizeUserAnswers
        })
      }
    }

    markAsCorrect(){
        let answer = this.state.correctAnswer;
        const teamOneScore = this.state.teamOneScore;
        const teamTwoScore = this.state.teamTwoScore;
        const currentGame = this.state.currentGame || {};
        currentGame.answered = true;
        if(currentGame.question && currentGame.question.questionType === "time"){
            answer = answer.split(":")[1] + ":" + answer.split(":")[2]
            currentGame.correctAnswer = answer;
        } else if(currentGame.question && currentGame.question.questionType === "score"){
            answer = teamOneScore + " - " + teamTwoScore;
            currentGame.correctAnswer = answer;
        } else {
            currentGame.correctAnswer = answer;
        }
        this.organizeRewardsToSend(currentGame, answer)
    }

    getSnapshotFromEndpoint(endpoint, numberOfResults=null, searchData=null, orderBy=null) {
        let baseRef = appDatabasePrimaryFunctions.ref(endpoint);
        if(orderBy){
            baseRef = baseRef.orderByChild(orderBy)
        }
        if(numberOfResults){
            baseRef = baseRef.limitToFirst(numberOfResults);
        }
        return baseRef.once('value').then(function(snapshot){
            return snapshot;
        })
    }

    async organizeUserAnswers(numberOfResults=0, searchData=null){
        let finalResponses = [];
        const currentGame = this.state.currentGame || {};
        await Promise.all([this.getSnapshotFromEndpoint('userAnswers', numberOfResults, searchData, 'timeStamp'), this.getSnapshotFromEndpoint('emailsSent'), this.getSnapshotFromEndpoint('users')]).then(async function(snapshots){
            let userAnswers = {};
            if(snapshots[0].exists() && snapshots[0].val()){
                userAnswers = snapshots[0].val();
            }
            let emailsSent = {};
            if(snapshots[1].exists() && snapshots[1].val()){
                emailsSent = snapshots[1].val();
            }
            let users = {};
            if(snapshots[2].exists() && snapshots[2].val()){
                users = snapshots[2].val();
            }
            const question = currentGame.question;
            if(question){
                for(let userAnswerIndex in userAnswers){
                    const answer = userAnswers[userAnswerIndex];
                    if(answer){
                        let answerObject = {}
                        const userEmail = users[answer.uid].email;
                        const userAnswerObject = answer || {};
                        let userAnswer = (valueDoesExist(userAnswerObject.answer) ? userAnswerObject.answer : "")
                        answerObject['email'] = userEmail;
                        answerObject['answer'] = userAnswer;
                        answerObject['timeStamp'] = userAnswerObject.timeStamp || "";
                        answerObject['uid'] = userAnswerObject.uid || "";
                        const emailSentCheck = emailsSent[btoa(userEmail)] || emailsSent[userAnswerObject.uid]
                        if(emailSentCheck){
                            answerObject['rewardSent'] = emailSentCheck.name || "";
                            answerObject['code'] = emailSentCheck.code || "";
                        }
                        if(searchData){
                            const cleanSearchData = searchData.toLowerCase();
                            const lowerCaseEmail = userEmail.toLowerCase();
                            const lowerCaseUserAnswer = userAnswer.toString().toLowerCase();
                            let lowerCaseRewardName = "";
                            if(emailsSent[userAnswerObject.uid] && emailsSent[userAnswerObject.uid].name){
                                lowerCaseRewardName = emailsSent[userAnswerObject.uid].name.toLowerCase();
                            }
                            if(lowerCaseEmail.indexOf(cleanSearchData) !== -1 || (lowerCaseUserAnswer && lowerCaseUserAnswer.indexOf(cleanSearchData) !== -1)  || lowerCaseRewardName.indexOf(cleanSearchData) !== -1){
                                finalResponses.push(answerObject);
                            }
                        } else {
                            finalResponses.push(answerObject);
                        }
                    }
                }
            }
        })
        return finalResponses
    }

    async organizeRewardsToSend(currentGame, answer){
        const tenantVariables = this.state.tenantVariables;
        const incorrectAnswers = [];
        const correctAnswers = [];
        const incorrectAnswersNames = [];
        const correctAnswersNames = [];
        const userAnswers = await appDatabasePrimaryFunctions.ref("userAnswers").orderByChild('timeStamp').once('value').then(function (snapshot){
            return snapshot;
        });
        const users = await appDatabasePrimaryFunctions.ref("users").once('value').then( function (snapshot){
            return snapshot.val()
        });
        if(userAnswers.numChildren() > 0) {
            userAnswers.forEach(function(userAnswerRef){
                if(userAnswerRef.exists){
                    const userAnswer = userAnswerRef.val();
                    const user = users[userAnswer.uid];
                    const userEmail = user.email;
                    if (parseInt(userAnswer.answer) === parseInt(answer)) {
                        correctAnswers.push(userEmail)
                        if(tenantVariables.collectName && tenantVariables.nameInEmail){
                            correctAnswersNames.push(user.name)
                        }
                    } else {
                        incorrectAnswers.push(userEmail)
                        if(tenantVariables.collectName && tenantVariables.nameInEmail){
                            incorrectAnswersNames.push(user.name)
                        }
                    }
                }
            })
        }
        this.sendAllEmails(correctAnswers, incorrectAnswers, currentGame, correctAnswersNames, incorrectAnswersNames)
    }

    async onNextPage() {
        const newAmountToShow = this.state.itemsToShow + this.state.itemsPerPage;
        const responseFromOrganize = await this.organizeUserAnswers(newAmountToShow);
        this.setState({
            showStats:true,
            loading: false,
            itemsToShow: newAmountToShow,
            currentUserAnswersState: responseFromOrganize
        })
    }

    async searchFirebaseData(searchData){
        this.setState({
            loading: true
        })
        let numberOfResults = this.state.itemsPerPage;
        if(searchData){
            numberOfResults = null;
        }
        const organizeUserAnswers = await this.organizeUserAnswers(numberOfResults, searchData);
        this.setState({
            loading: false,
            currentUserAnswersState: organizeUserAnswers
        })
    }

    render() {
      let selectedGame = this.state.currentGame || null;
      let gameName = null;
      let questionList = this.state.questionsList || [];
      let players = this.state.userGameHistory || 0;
      let userAnswersCount = this.state.userAnswerCount || 0;
      let currentUserAnswersState = this.state.currentUserAnswersState || [];
      let hideEndGameButton = false;
      let votingStarted = false;
      let questionAnswered = false;
      let question = {};
      let startTimeFirstPart = null;
      let startTimeSecondPart = null;
      let endTimeSecondPart = null;
      let endTimeFirstPart = null;
      let losing_prize = {};
      let main_prize = {};
      let other_prize = {};
      let gameScheduledStatus = "completed";
      let questionType;
      if(selectedGame && selectedGame.question){
        if(selectedGame.scheduleInfo){
            let performAtTime = returnTimeStampInMilliseconds(selectedGame.scheduleInfo.performAt);
            let endAtTime = returnTimeStampInMilliseconds(selectedGame.scheduleInfo.endAt);
            startTimeFirstPart = new Date(performAtTime).toLocaleDateString();
            startTimeSecondPart = new Date(performAtTime).toLocaleTimeString();
            endTimeFirstPart = new Date(endAtTime).toLocaleDateString();
            endTimeSecondPart = new Date(endAtTime).toLocaleTimeString();
            gameScheduledStatus = selectedGame.scheduleInfo.status
        }
        question = selectedGame.question;
        losing_prize = selectedGame.losing_prize || {};
        main_prize = selectedGame.main_prize || {};
        other_prize = selectedGame.other_prize || {};
        questionAnswered = selectedGame.answered;
        votingStarted = selectedGame.active;
        questionType = question.questionType;
        if(questionType === "slider"){
          questionType = "Slider";
        } else if(questionType === "write_in"){
          questionType = "Write In"
        } else if(questionType === "time"){
          questionType = "Time"
        } else if(questionType === "score"){
          questionType = "Game Score"
        }
        if((selectedGame.id && selectedGame.answered) || votingStarted || (gameScheduledStatus === "active" && !selectedGame.firstStart) || (gameScheduledStatus === "scheduled" && !selectedGame.firstStart)){
          hideEndGameButton = true;
        }
      } else {
        selectedGame = null
        hideEndGameButton = true
      }
      const vm = this;
      return (
        <div className="admin-wrapper">
          <div className="loading-screen" style={{display: this.state.loading ? 'block' : 'none' }}/>
          <SideMenu/>
          <TopMenu/>
          <div className="admin-main-panel">
            <div className="container" style={{padding:'20px', backgroundColor:'#e3eaef'}}>
              <div className="row">
                <div className="col-md-2" style={{display: questionAnswered ? '' : 'none'}}>
                  <div className="card" style={{backgroundColor:'#fe3b4b', width:'100%', textAlign: 'center', height:'50px'}}>
                    <p style={{lineHeight:'50px'}}>Game Ended</p>
                  </div>
                </div>
                <div className="col-md-3" style={{display: questionAnswered ? 'none' : ''}}>
                  <div className="card" style={{backgroundColor:'#00c78c', width:'100%', textAlign: 'center', height:'50px', display: selectedGame && votingStarted ? '' : 'none', float:'left'}}>
                    <p style={{lineHeight:'50px'}}>Predictions Are Live</p>
                  </div>
                  <div className="card" style={{backgroundColor:'#fe3b4b', width:'100%', textAlign: 'center', height:'50px', display: selectedGame && !votingStarted ? '' : 'none', float:'left'}}>
                    <p style={{lineHeight:'50px'}}>Predictions Are Not Live</p>
                  </div>
                </div>
                <div className="col-md-3">
                    <button onClick={() => this.stopGame()} className="btn btn-primary btn-lg end-game-button" style={{display: selectedGame && !questionAnswered && votingStarted ? '' : 'none', float:'left', height:'52px' }}>End Predictions</button>
                    <button onClick={() => this.startGame()} className="btn btn-primary btn-lg start-game-button" style={{display: selectedGame && !votingStarted && !questionAnswered ? '' : 'none', float:'left', height:'52px' }}>Start Predictions</button>
                </div>
                <div className="col-md-2">
                  <button onClick={() => this.endGame()} className="btn btn-primary btn-lg" style={{display: hideEndGameButton ? 'none' : '', float:'left', height:'52px' }}>Answer</button>
                </div>
                <div className="col-md-4" style={{textAlign:'right'}}>
                  <a className="btn btn-outline-danger btn-sm" onClick={() => this.resetGame()} style={{display: selectedGame && selectedGame.id ? '' : 'none', marginTop:'7px', float:'right'}}>Reset Game</a>
                  <button onClick={() => this.toggle()} className="btn btn-primary btn-lg create-game-button" style={{float:'right', marginRight:'5px'}}>New Game</button>
                </div>
              </div>
            </div>
            <div style={{display: !selectedGame ? 'none': 'block'}}>
                <div className="container">
                    <div className="row">
                        {startTimeFirstPart &&
                        <div className="col-md-4"
                             style={{color: "black", border: "solid 1px #d3d3d3", margin: 10, borderRadius: 5}}>
                            <div className="row">
                                <div className="col-md-6">
                                    <u style={{color: "#353839"}}>Starts</u>
                                    <br/>
                                    <span style={{fontSize: 18}}>{startTimeFirstPart}<br/>{startTimeSecondPart}</span>
                                </div>
                                <div className="col-md-6">
                                    <u style={{color: "#353839"}}>Ends</u>
                                    <br/>
                                    <span style={{fontSize: 18}}>{endTimeFirstPart}<br/>{endTimeSecondPart}</span>
                                </div>
                            </div>
                        </div>
                        }
                        <div className="col-md-4" style={{color: "black", border: "solid 1px #d3d3d3", marginTop:10, marginBottom:10, marginLeft:10, borderRadius: 5}}>
                            <div className="row">
                                <div className="col-md-6" style={{textAlign:'center'}}>
                                    <span style={{fontSize:12}}>Current Game Players</span>
                                    <footer className="value-text">{players}</footer>
                                </div>
                                <div className="col-md-6" style={{textAlign:'center'}}>
                                    <span style={{fontSize:12}}>Answers</span>
                                    <footer className="value-text">{userAnswersCount}</footer>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{display: !selectedGame ? 'none': 'block'}}>
              <div className="card-body" style={{padding:0}}>
                <ul className="nav nav-tabs nav-justified nav-bordered mb-3">
                    <li className="nav-item" onClick={()=> this.switchStatsPrizes()}>
                        <a href="#" data-toggle="tab" aria-expanded="false" className="nav-link active" id="showStats" style={{backgroundColor:'#fafbfe'}}>
                            <i className="mdi mdi-home-variant d-lg-none d-block mr-1"/>
                            <span className="d-none d-lg-block">Stats</span>
                        </a>
                    </li>
                    <li className="nav-item" onClick={()=> this.switchStatsPrizes()}>
                        <a href="#" data-toggle="tab" aria-expanded="true" className="nav-link" id="showPrizes" style={{backgroundColor:'#fafbfe'}}>
                            <i className="mdi mdi-account-circle d-lg-none d-block mr-1"/>
                            <span className="d-none d-lg-block">Results</span>
                        </a>
                    </li>
                </ul>
                <div style={{display: this.state.showStats ? 'block' : 'none'}}>
                    <div className="row" style={{marginLeft: 10, marginRight: 10}}>
                        <div className="export-button-styles btn btn-primary btn-lg download-button" onClick={()=>this.downloadUsers()}>
                            <span className="fa fa-arrow-circle-down"/> Download Participants
                        </div>
                        <div className="form-group" style={{marginLeft: 'auto', marginBottom: 'auto', marginTop: 'auto'}}>
                            <input id="searchQuery" name="searchQuery" className="form-control" placeholder="search" type="text" onChange={()=>{
                                const vm = this;
                                clearTimeout(this.timeoutId); // no-op if invalid id
                                this.timeoutId = setTimeout(function(){
                                    let input = document.getElementById('searchQuery').value;
                                    vm.searchFirebaseData(input);
                                }, 500);
                            }}/>
                        </div>
                    </div>
                    <div style={{height:'10px', width:'100%'}}/>
                  <table className="table table-striped" style={{color:'black'}}>
                    <tbody>
                      <tr>
                        <th>Email</th>
                        <th>Answer</th>
                        <th>Time Stamp</th>
                        <th>Reward Sent</th>
                        <th>Code</th>
                      </tr>
                      {
                        currentUserAnswersState.map(function(item,i){
                            return <tr key={i}>
                              <td style={{fontFamily:'Open Sans'}}>{item.email}</td>
                              <td style={{fontFamily:'Open Sans' }}>{item.answer}</td>
                              <td style={{fontFamily:'Open Sans' }}>{convertTimeStampToHumanReadable(parseInt(item.timeStamp))}</td>
                              <td style={{fontFamily:'Open Sans' }}>{item.rewardSent || "NONE"}</td>
                              <td style={{fontFamily:'Open Sans' }}>{item.code || "NONE"}</td>
                            </tr>
                        }, this)
                      }
                      {currentUserAnswersState && userAnswersCount > currentUserAnswersState.length && !document.getElementById('searchQuery').value  &&
                          <button className="btn btn-primary" onClick={()=>this.onNextPage()}>
                              More
                          </button>
                      }
                    </tbody>
                  </table>
                </div>
                <div style={{display: !this.state.showStats ? 'block' : 'none'}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6" style={{textAlign:"center"}}>
                                <h4 style={{color:'black'}}>Rewards</h4>
                                {main_prize && main_prize.rewardName &&
                                    <Accordion style={{margin: 10}}>
                                        <AccordionItem className="blackBackgroundColor" bodyClassName="changeBackgroundColor" title="Main Prize" expanded={true}>
                                            <div style={{color: "black"}}>
                                                <span>{main_prize.amount}, {main_prize.rewardName}</span>
                                            </div>
                                        </AccordionItem>
                                    </Accordion>
                                }
                                {other_prize && other_prize.rewardName &&
                                    <Accordion style={{margin: 10}}>
                                        <AccordionItem className="blackBackgroundColor" bodyClassName="changeBackgroundColor" title="Other Prize" expanded={true}>
                                            <div style={{color: "black"}}>
                                                <span>{other_prize.rewardName}</span>
                                            </div>
                                        </AccordionItem>
                                    </Accordion>
                                }
                                {losing_prize && losing_prize.rewardName &&
                                    <Accordion style={{margin: 10}}>
                                        <AccordionItem className="blackBackgroundColor" bodyClassName="changeBackgroundColor" title="Losing Prize" expanded={true}>
                                            <div style={{color: "black"}}>
                                                <span>{losing_prize.rewardName}</span>
                                            </div>
                                        </AccordionItem>
                                    </Accordion>
                                }
                            </div>
                            <div className="col-md-6 justify-content-center" style={{textAlign:"center"}}>
                                <h4 style={{color:'black'}}>Prediction</h4>
                                <div style={{backgroundColor: "#FAFBFE", padding:10}}>
                                    <p style={{color:'black'}}>{question.questionText}</p>
                                    <p style={{color:'black'}}>Type of prediction: {questionType}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
          <Modal isOpen={this.state.modal} toggle={this.toggle} style={{width: '90%'}} id="myModal">
              <div className="card">
                <div className="card-body">
                  <h4 className="header-title mb-3"> Create Game</h4>
                  <div id="rootwizard">
                    <ul className="nav nav-pills bg-dark-light nav-justified form-wizard-header mb-3">
                        <li className="nav-item" onClick={() => this.toggleWizardLocation('first', 'second', 'third')}>
                            <a href="#" className="nav-link rounded-0 pt-2 pb-2 active show" id="first1">
                                <span className="fa fa-pencil-square-o"/>
                                <span className="d-none d-sm-inline"> The Basics</span>
                            </a>
                        </li>

                        <li className="nav-item" onClick={() => this.toggleWizardLocation('second', 'first', 'third')}>
                            <a href="#" className="nav-link rounded-0 pt-2 pb-2" id="second1">
                                <span className="fa fa-trophy"/>
                                <span className="d-none d-sm-inline"> Add Prizes</span>
                            </a>
                        </li>
                        <li className="nav-item" onClick={() => this.toggleWizardLocation('third', 'first', 'second')}>
                            <a href="#" className="nav-link rounded-0 pt-2 pb-2" id="third1">
                                <span className="fa fa-sign-out"/>
                                <span className="d-none d-sm-inline"> Finish</span>
                            </a>
                        </li>
                    </ul>

                    <div className="tab-content mb-0 b-0" style={{fontFamily:'Roboto'}}>

                        <div className="tab-pane active show" id="first">
                            <form id="accountForm" method="post" action="#" className="form-horizontal">
                                <div className="row">
                                    <div className="col-12">
                                      <div className="form-group row mb-3">
                                        <label className="col-md-3 col-form-label" htmlFor="gameName"> Game Name</label>
                                        <div className="col-md-9">
                                          <input id="gameName" name="gameName" type="text" className="form-control" value={this.state.gameName} onChange={this.handleChange} placeholder="12/11 vs MonStars" />
                                        </div>
                                      </div>
                                        <style dangerouslySetInnerHTML={{
                                            __html: [
                                                '.react-datepicker-time__input {',
                                                    'width: 100% !important;',
                                                '}'
                                            ].join('\n')
                                        }}/>
                                        <div className="form-group row">
                                            <label className="col-md-3 col-form-label" htmlFor="startTime"> Set Start Time</label>
                                            <div className="col-md-9">
                                                <DatePicker showTimeInput dateFormat="Pp" selected={this.state.startTime} onChange={date => this.setState({startTime: date})}/>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-md-3 col-form-label" htmlFor="endTime"> Set End Time</label>
                                            <div className="col-md-9">
                                                <DatePicker showTimeInput dateFormat="Pp" selected={this.state.endTime} onChange={date => this.setState({endTime: date})}/>
                                            </div>
                                        </div>
                                      <div className="form-group row mb-3">
                                        <label className="col-md-3 col-form-label" htmlFor="name3"> Select Prediction</label>
                                        <div className="col-md-9">
                                          <select className="form-control" name="question_select" id="question_select" value={this.state.question_select} onChange={this.handleChange}>
                                            <option/>
                                            {
                                              questionList.map(function(item,index){
                                                return(
                                                  <option value={item.key} key={index}>{item.questionText}</option>
                                                )
                                              })
                                            }
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                </div>
                            </form>
                        </div>

                      <div className="tab-pane" id="second">
                        <div className="form-horizontal">
                          <div className="row">
                            <div className="col-12">
                                <Accordion style={{margin:10}}>
                                    <AccordionItem className="accordian-header" title={`Main Prize`}>
                                        <div style={{height:'10px', width:'100%'}}/>
                                        <div className="form-group row mb-3">
                                            <label className="col-md-3 col-form-label" htmlFor="main_prize"> Main Prize</label>
                                            <div className="col-md-9">
                                                <select className="form-control" name="main_prize" id="main_prize" value={this.state.main_prize} onChange={this.handleChange}>
                                                    <option/>
                                                    {
                                                        this.state.ticketList.map(function(item,index){
                                                            return(
                                                                <option value={item.key} key={index}>{item.rewardName}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group row mb-3">
                                            <label className="col-md-3 col-form-label" htmlFor="main_prize_amount"> Main Prize Amount</label>
                                            <div className="col-md-4">
                                                <input id="main_prize_amount" name="main_prize_amount" type="number" className="form-control" value={this.state.main_prize_amount} onChange={this.handleChange} placeholder="5" />
                                            </div>
                                            <center className="col-md-1 col-sm-12">
                                                <input id="main_prize_codes" name="main_prize_codes" type="checkbox" checked={this.state.main_prize_codes} onChange={this.handleChange} />
                                            </center>
                                            <label className="col-md-4 col-form-label" htmlFor="main_prize_codes">Add Codes</label>
                                        </div>
                                        <div className="form-group row mb-3" style={{display: this.state.main_prize_codes ? 'flex' : 'none' }}>
                                            <label htmlFor="main_prize_codesArray" className="col-md-3 col-form-label">Enter Codes (code1,code2)</label>
                                            <div className="col-md-9">
                                                <textarea value={this.state.main_prize_codesArray} className="form-control" name="main_prize_codesArray" onChange={this.handleChange} placeholder="code1,code2,code3"/>
                                            </div>
                                        </div>
                                    </AccordionItem>
                                    <AccordionItem className="accordian-header" title={`Other Winner Prize`}>
                                        <div style={{height:'10px', width:'100%'}}/>
                                        <div className="form-group row mb-3">
                                            <label className="col-md-3 col-form-label" htmlFor="other_prize"> Other Prize</label>
                                            <div className="col-md-9">
                                                <select className="form-control" name="other_prize" id="other_prize" value={this.state.other_prize} onChange={this.handleChange}>
                                                    <option/>
                                                    {
                                                        this.state.ticketList.map(function(item,index){
                                                            return(
                                                                <option value={item.key} key={index}>{item.rewardName}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </AccordionItem>
                                    <AccordionItem className="accordian-header" title={`Losing Prize`}>
                                        <div style={{height:'10px', width:'100%'}}/>
                                        <div className="form-group row mb-3">
                                            <label className="col-md-3 col-form-label" htmlFor="losing_prize"> Losing Prize</label>
                                            <div className="col-md-9">
                                                <select className="form-control" name="losing_prize" id="losing_prize" value={this.state.losing_prize} onChange={this.handleChange}>
                                                    <option/>
                                                    {
                                                        this.state.ticketList.map(function(item,index){
                                                            return(
                                                                <option value={item.key} key={index}>{item.rewardName}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </AccordionItem>
                                </Accordion>
                            </div>
                          </div>
                        </div>
                      </div>

                        <div className="tab-pane fade" id="third">
                            <form id="otherForm" method="post" action="#" className="form-horizontal"/>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="text-center">
                                            <h2 className="mt-0">
                                                <i className="mdi mdi-check-all"/>
                                            </h2>
                                            <h3 className="mt-0">Finish</h3>

                                            <div className="row form-group">
                                              <div className="col-md-12">
                                                <p>You are all set to create a game!  Before clicking "Create Game" make sure all the settings are the way you want. Feel free to navigate back to edit anything you like!</p>
                                              </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                        </div>

                        <ul className="list-inline wizard mb-0">
                            <li className="previous list-inline-item" style={{display: this.state.wizardLocation === 'first' ? 'none' : '' }}><a href="#" className="btn btn-primary btn-lg previous-wizard-button" onClick={() => this.navButtonClicked('prev')}>Previous</a>
                            </li>
                            <li className="next list-inline-item float-right"><a href="#" className="btn btn-primary btn-lg next-wizard-button" onClick={() => this.navButtonClicked('next')} style={{display: this.state.wizardLocation === 'third' ? 'none' : '' }}>Next</a></li>
                            <li className="next list-inline-item float-right"><a href="#" className="btn btn-primary btn-lg creategame-wizard-button" onClick={() => this.createGame()} style={{display: this.state.wizardLocation === 'third' ? '' : 'none' }}>Create Game</a></li>
                        </ul>

                      </div>
                    </div>
                </div>
              </div>
          </Modal>
          <Modal isOpen={this.state.predictionsAnsweredModal} toggle={this.togglePredictionsAnsweredModal} style={{width: '90%'}} id="myModal2">
              <div className="card">
                  <div className="card-body">
                      <h4 className="header-title mb-3"> Predictions To Answer</h4>
                      <p>{question.questionText}</p>
                      {question.questionType === "time" &&
                          <div className="form-group mb-3">
                              <TimePicker
                                  onChange={this.handleTimeChange}
                                  value={this.state.correctAnswer}
                                  name="correctAnswer"
                                  minutePlaceholder="00"
                                  secondPlaceholder="00"
                                  defaultValue="00:00"
                                  disableClock={true}
                                  maxDetail="second"
                                  format="mm:ss"
                                  clearIcon={null}
                                  className="timeInput"
                              />
                          </div>
                      }
                      {(question.questionType === "write_in" || question.questionType === "slider") &&
                          <div className="form-group mb-3">
                              <input name="correctAnswer" className="form-control" type="number" value={this.state.correctAnswer} onChange={this.handleChange}/>
                          </div>
                      }
                      {question.questionType === "score" &&
                      <div className="form-group mb-3">
                          <label htmlFor="teamOneScore">Team One Score</label>
                          <input name="teamOneScore" className="form-control" type="number" value={this.state.teamOneScore} onChange={this.handleChange}/>
                          <label htmlFor="teamTwoScore">Team Two Score</label>
                          <input name="teamTwoScore" className="form-control" type="number" value={this.state.teamTwoScore} onChange={this.handleChange}/>
                      </div>
                      }
                      <button className="btn btn-success" onClick={() => vm.markAsCorrect()}>Answer Prediction and Send Emails</button>
                  </div>
              </div>
          </Modal>
       </div>
      );
    }
}

export default SetUpGame;
